import {Controller} from "@hotwired/stimulus"

import {Calendar} from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin, {Draggable} from '@fullcalendar/interaction';

import {FetchRequest} from "@rails/request.js"

function toLocalISOString(date) {
    var tzo = -date.getTimezoneOffset(),
        dif = tzo >= 0 ? '+' : '-',
        pad = function (num) {
            var norm = Math.floor(Math.abs(num));
            return (norm < 10 ? '0' : '') + norm;
        };
    return date.getFullYear() +
        '-' + pad(date.getMonth() + 1) +
        '-' + pad(date.getDate()) +
        'T' + pad(date.getHours()) +
        ':' + pad(date.getMinutes()) +
        ':' + pad(date.getSeconds()) +
        dif + pad(tzo / 60) +
        ':' + pad(tzo % 60);
}

// This controller is designed to work with the FullCalendar library
// It provides a simple way to add, edit, and delete events on a calendar
// Example usage:
// <div class="content-container" data-controller="calendar modal clickable" data-calendar-url-value="<%= tasks_blocks_path(format: :json) %>" data-modal-disable-backdrop="true" data-action="calendar:add->modal#open calendar:edit->modal#open">
// To get the right modal to show, make sure you set the data-calendar-add-target and data-calendar-edit-target to the correct form
//
// It also provides a way to drag and drop elements onto the calendar
// Make sure you set the data-calendar-droppable-value to true
// And define the draggable elements with the data-calendar-draggable-target
// And define data-event attributes on the draggable elements to pass the event data to the calendar
// See https://fullcalendar.io/docs/external-dragging

// Note: we are not using any timeZone plugins and so fullCalendar will be using the UTC time zone hack
// So all time zones are dropped, and the time is assumed to be in UTC
// this means that returned dates will also be in UTC
export default class extends Controller {
    static targets = ["calendar", "start", "end", "add", "edit", "title", "show", "delete", "draggable"]

    static values = {
        url: {type: String, default: '/timers/blocks.json'},
        droppable: {type: Boolean, default: false},
        view: {type: String, default: 'timeGridWeek'},
        selectPastOnly: {type: Boolean, default: false},
        scrollTime: String
    }

    connect() {
        let _this = this
        if (!this.hasAddTarget) {
            console.warn("No add form target specified for your calendar add events")
        }
        if (!this.hasEditTarget) {
            console.warn("No edit form target specified for your calendar edit events")
        }
        // Get the current time in HH:MM:SS format
        let scrollTime
        if (this.hasScrollTimeValue) {
            scrollTime = this.scrollTimeValue
        } else {
            var oneHourBeforeNow = new Date();
            oneHourBeforeNow.setSeconds(oneHourBeforeNow.getSeconds() - 3600);
            oneHourBeforeNow.setMinutes(0);

            scrollTime = oneHourBeforeNow.toTimeString().split(' ')[0];
        }

        this.calendar = new Calendar(this.calendarTarget, {
            events: this.urlValue,
            plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
            headerToolbar: {
                left: 'prev,next today',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay customButton'
            },
            customButtons: {
                customButton: {
                    text: 'zoom',
                    click: () => this.toggleSlotDuration()
                }
            },
            slotDuration: '00:15:00',
            slotLabelInterval: "01:00",
            initialView: this.viewValue,
            scrollTime: scrollTime,
            nowIndicator: true,
            editable: true,
            selectable: true,
            droppable: this.droppableValue,
            selectMirror: true,
            selectAllow: function (select) {
                if (!_this.selectPastOnlyValue) return true;

                var now = new Date();
                return select.start <= now;
            },
            select: function (selectionInfo) {
                let startStr = toLocalISOString(selectionInfo.start).slice(0, 16)
                let endStr = toLocalISOString(selectionInfo.end).slice(0, 16)
                _this.startTarget.value = startStr
                _this.endTarget.value = endStr
                _this.setFormAdd();
                _this.dispatch("add")
            },
            eventClick: function (info) {
                info.jsEvent.preventDefault()
                _this.setFormEdit(info)
                _this.dispatch("edit")
            },
            eventDrop: function (info) {
                _this.update(info)
            },
            eventResize: function (info) {
                _this.update(info)
            },
            eventReceive: function (info) {
                _this.update(info)
            }
        });
        this.calendar.render();

        if (this.droppableValue) {
            this.draggableTargets.forEach((element) => {
                new Draggable(element)
            })
        } else {
            console.warn("Droppable is false, not setting up draggable elements")
        }
    }

    async update(info) {
        let _this = this
        let updateUrl = info.event.extendedProps.update_url
        const request = new FetchRequest("put", updateUrl, {
            body:
                JSON.stringify(
                    {
                        block:
                            {
                                start: toLocalISOString(info.event.start).slice(0, 16),
                                end: toLocalISOString(info.event.end).slice(0, 16)
                            }
                    }
                ),
            responseKind: "json"
        })
        request.perform().then(
            response => {
                if (!response.ok) {
                    console.warn(`Error updating block: ${response.statusCode}`)
                    console.warn(response)
                }
            }
        ).catch(error => {
                console.error('Error updating block')
                console.error(error)
            }
        )
    }

    setFormAdd() {
        this.addTarget.classList.remove("hidden")
        this.editTarget.classList.add("hidden")
        this.titleTarget.innerText = "Add"
        let titleElement = this.addTarget.querySelector("input[name='block[title]']")
        setTimeout(() => titleElement.focus(), 200)
    }

    setFormEdit(info) {
        const action = info.event.extendedProps.update_url
        const showUrl = info.event.extendedProps.show_url
        const startStr = toLocalISOString(info.event.start).slice(0, 16)

        // Interestingly, fullcalendar only returns an end date if it falls on a calendar boundary
        // otherwise it is null, and we need to calculate it from the original duration prop
        let endStr
        if (info.event.end) {
            endStr = toLocalISOString(info.event.end).slice(0, 16)
        } else {
            const start = new Date(info.event.start)
            const duration = info.event.extendedProps.original_duration * 1000
            const end = new Date(start.getTime() + duration)
            endStr = toLocalISOString(end).slice(0, 16)
        }
        var title = info.event.title
        // strip progress indicators such as ✅️🚫️🔄️ from the beginning of the title
        // strip status messages such as - DONE! or - CANCELLED! from the end of the title
        // strip leading and trailing whitespace
        title = title.replace(/^[✅️🚫️🔄️]+/, "").replace(/- (DONE|CANCELLED)!?$/, "").trim()
        
        this.editTarget.setAttribute("action", action)
        if (this.hasShowTarget) this.showTarget.setAttribute("href", showUrl)
        if (this.hasDeleteTarget) this.deleteTarget.setAttribute("action", action)

        this.editTarget.querySelector("input[name='block[start]']").value = startStr
        this.editTarget.querySelector("input[name='block[end]']").value = endStr
        let titleElement = this.editTarget.querySelector("input[name='block[title]']")
        titleElement.value = title

        // Add or update the CSRF token
        let csrfTokenInput = this.editTarget.querySelector("input[name='authenticity_token']");
        if (!csrfTokenInput) {
            csrfTokenInput = document.createElement("input");
            csrfTokenInput.setAttribute("type", "hidden");
            csrfTokenInput.setAttribute("name", "authenticity_token");
            this.editTarget.appendChild(csrfTokenInput);
        }
        const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
        csrfTokenInput.setAttribute("value", token);

        this.addTarget.classList.add("hidden")
        this.editTarget.classList.remove("hidden")
        this.titleTarget.innerText = "Edit"
        setTimeout(() => titleElement.focus(), 200)
    }

    unselect() {
        this.calendar.unselect()
    }

    toggleSlotDuration() {
        console.log("Toggling slot duration")
        const currentDuration = this.calendar.getOption('slotDuration');
        const newDuration = currentDuration === '00:15:00' ? '00:05:00' : '00:15:00';
        this.calendar.setOption('slotDuration', newDuration);
    }
}
