import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "submit",
    "cancel",
    "other" ]
  // submit is optional, if not specified, the controller will:
  // 1. submit the form it is defined in (if it is defined in a form)
  // 2. submit the form the input field is in (if it is defined in an input field)

  connect() {
    console.log(`easy-submit connected to this.element: ${this.element}`)
  }

  submit() {
    console.log(`submitting the form`)
    if (this.hasSubmitTarget) {
      this.submitTarget.click()
    } else if (this.element.tagName === 'FORM') {
      this.element.requestSubmit()
    } else if (this.element.tagName === 'INPUT' && this.element.form) {
      this.element.form.requestSubmit()
    } else {
      alert("Sorry, I don't know how to submit this form.")
    }
  }

  cancel() {
    console.log('cancelling form')
    if (this.cancelTarget) {
      this.cancelTarget.click()
    } else {
      alert('No cancel target found')
    }
  }

  other() {
    console.log('other button clicked')
    if (this.otherTarget) {
      this.otherTarget.click()
    } else {
      alert('No other target found')
    }
  }
}
