import { Controller } from "@hotwired/stimulus"

/**
 * Controller for toggling a data attribute
 * and optionally storing the boolean state into localStorage
 */
export default class extends Controller {

    static values = {
        localStorageId: String,
        
        // If true, this will set the localStorageId value to false if it's the first time the user sees the element (requires localStorageId)
        // Set this to true if you want to show the element by default the first time the user sees it and then hide it on subsequent visits
        // Once the user interacts with the element, the localStorage value will be used
        untoggleAfterFirstSeen: String, 
        
        classNames: String,
        attributeNames: String,
        targetSelector: String,
        globalSelect: String,
        isToggledInitial: { type: Boolean, default: false }
    }

    connect() {
        this.isToggled = this.isToggledInitialValue;
        console.log('connected', {
            isToggledInitial: this.isToggledInitialValue,
            isToggled: this.isToggled,
            globalSelectValue: this.globalSelectValue,
            targetSelectorValue: this.targetSelectorValue,
            classNamesValue: this.classNamesValue,
            attributeNamesValue: this.attributeNamesValue
        })
        if (this.localStorageIdValue) {
            const storedValue = this.getFromStorage();
            console.log('storedValue', storedValue);
            if (this.untoggleAfterFirstSeenValue === "true" && storedValue === null) {
                console.log('no stored value and untoggleAfterFirstSeenValue is true, updating localstorage');
                localStorage.setItem(this.localStorageIdValue, "false");
            } else if(storedValue !== null) {
                this.isToggled = storedValue === "true";
            }
        }
        this.update();
    }

    update() {
        console.log('updating', {
            isToggled: this.isToggled,
            globalSelectValue: this.globalSelectValue,
            targetSelectorValue: this.targetSelectorValue,
            classNamesValue: this.classNamesValue,
            attributeNamesValue: this.attributeNamesValue
        })
        const targets =
            (this.globalSelectValue)
                ? document.querySelectorAll(`${this.targetSelectorValue}`)
                : [this.element.closest(`${this.targetSelectorValue}`)];

        if (targets.length) {
            if(this.classNamesValue) {
                const classNames = this.classNamesValue.split(' ');
                
                classNames.forEach(className => {
                    targets.forEach( (target) => {
                        console.log('toggling', {
                            target,
                            className,
                            isToggled: this.isToggled,
                            classList: target.classList
                        })
                        target.classList.toggle(className, this.isToggled);
                    });
                });
            }            
            if(this.attributeNamesValue) {
                const attributeNames = this.attributeNamesValue.split(' ');
                attributeNames.forEach(attributeName => {
                    targets.forEach( (target) => target.setAttribute(attributeName, String(this.isToggled)));
                });    
            }

        }
        this.element.setAttribute('data-toggler-toggled', String(this.isToggled));
    }

    updateStorage() {
        if ( this.localStorageIdValue ) {
            localStorage.setItem( this.localStorageIdValue, String(this.isToggled) );
        }
      }

    getFromStorage() {
        if(this.localStorageIdValue) {
            return localStorage.getItem( this.localStorageIdValue);
        }
    }

    toggle(event) {
        if(event) {
            event.preventDefault();
        }
        this.isToggled = !this.isToggled;
        this.update();
        this.updateStorage();
        
        this.element.dispatchEvent(new CustomEvent("toggle", {
            detail: { isToggled: this.isToggled },
            bubbles: true
        }));
    }
}
