// Customizable command palette for advanced users
// Opens with cmd+k or ctrl+k by default
// https://github.com/excid3/ninja-keys

import { Controller } from "@hotwired/stimulus"
import "@gorails/ninja-keys"

export default class extends Controller {
  connect() {
    this.element.data = [
      {
        id: "Home",
        title: "Home",
        hotkey: "ctrl+H",
        icon: '<svg xmlns="http://www.w3.org/2000/svg" class="ninja-icon" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" /></svg>',
        handler: () => {
          Turbo.visit("/")
        }
      },
      {
        id: "Add",
        title: "Add",
        hotkey: "ctrl+A",
        handler: () => {
          Turbo.visit("/quick_add/new")
        }
      },
      {
        id: "Timer",
        title: "Timer",
        hotkey: "ctrl+T",
        handler: () => {
          Turbo.visit("/timers/new")
        }
      },
      {
        id: "Tasks",
        title: "Tasks",
        hotkey: "ctrl+Y",
        handler: () => {
          Turbo.visit("/tasks")
        }
      },
      {
        id: "Active",
        title: "Active",
        hotkey: "ctrl+1",
        handler: () => {
          Turbo.visit("/tasks")
        }
      },
      {
        id: "Inbox",
        title: "Inbox",
        hotkey: "ctrl+2",
        handler: () => {
          Turbo.visit("/tasks?search=@inbox")
        }
      },
      {
        id: "Today",
        title: "Today",
        hotkey: "ctrl+3",
        handler: () => {
          Turbo.visit("/tasks?search=@today")
        }
      },
      {
        id: "Next",
        title: "Next",
        hotkey: "ctrl+4",
        handler: () => {
          Turbo.visit("/tasks?search=@next")
        }
      },
      {
        id: "Later",
        title: "Later",
        hotkey: "ctrl+9",
        handler: () => {
          Turbo.visit("/tasks?search=@later")
        }
      },
      {
        id: "All",
        title: "All Tasks",
        hotkey: "ctrl+0",
        handler: () => {
          Turbo.visit("/tasks?search=@all")
        }
      },
      {
        id: "Quick",
        title: "Quick Timers",
        hotkey: "ctrl+Q",
        icon: '<svg xmlns="http://www.w3.org/2000/svg" class="ninja-icon" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" /></svg>',
        children: ['deep45', 'break5', 'break10', 'email10', 'social5'],
        handler: () => {
          ninja.open({ parent: 'Quick' })
          return {keepOpen: true}
        }
      },
      {
        id: "social5",
        title: "Social Media 5 min",
        parent: "Quick",
        handler: () => {
          Turbo.visit("/timers/new?task=Social%20Media&duration=5")
        },
      },
      {
        id: "email10",
        title: "Email 10 min",
        parent: "Quick",
        handler: () => {
          Turbo.visit("/timers/new?task=Email&duration=10")
        },
      },
      {
        id: "break5",
        title: "Break 5 min",
        parent: "Quick",
        handler: () => {
          Turbo.visit("/timers/new?task=Break&duration=5")
        },
      },
      {
        id: "break10",
        title: "Break 10 min",
        parent: "Quick",
        handler: () => {
          Turbo.visit("/timers/new?task=Break&duration=10")
        },
      },
      {
        id: "deep45",
        title: "Deep Work 45 min",
        parent: "Quick",
        handler: () => {
          Turbo.visit("/timers/new?task=Deep%20Work&duration=45")
        },
      },
      // {
      //   id: "Accounts",
      //   title: "Accounts",
      //   hotkey: "ctrl+A",
      //   icon: '<svg xmlns="http://www.w3.org/2000/svg" class="ninja-icon" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" /></svg>',
      //   handler: () => {
      //     Turbo.visit("/accounts")
      //   }
      // },
      {
        id: "Profile",
        title: "Profile",
        hotkey: "ctrl+P",
        icon: '<svg xmlns="http://www.w3.org/2000/svg" class="ninja-icon" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>',
        handler: () => {
          Turbo.visit("/users/edit")
        }
      },
      {
        id: "Notifications",
        title: "Notifications",
        icon: '<svg xmlns="http://www.w3.org/2000/svg" class="ninja-icon" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" /></svg>',
        handler: () => {
          Turbo.visit("/notifications")
        }
      },
    ];
  }
}
